export type RotatorElement = {
    name?: string,
    adContainer: Element,
    interval: number
}

interface RotatorData {
    ads: Element[],
    iterator: Generator,
    intervalID?: number
}

export default class AdRotator {
    private _rotators: RotatorElement[] = []
    private _rotatorData: Map<RotatorElement, RotatorData> = new Map()

    public add(rotatorElem: RotatorElement) {
        let rotatorAds = rotatorElem.adContainer.querySelectorAll(".bsaProItem")

        switch ( rotatorAds.length ) {
            case 0:
                console.warn(`Error: Container ${rotatorElem.name || "\"undefined\""} has no ads to rotate through!`);
                return;
            case 1:
                return;
            default:
                break;
        }

        const adList = [...rotatorAds]
        this._rotators.push(rotatorElem)
        this._rotatorData.set(rotatorElem, {
            ads: adList,
            iterator: this.displayAd( adList )
        })

    }
    public init() {
        for ( let rotator of this._rotators ) {
            let data = this._rotatorData.get(rotator)
            let intervalID = window.setInterval(
                () => { data.iterator.next() },
                rotator.interval
            )
            data.intervalID = intervalID
        }
    }

    private *displayAd(adElems: Element[]) {
        const adCount = adElems.length
        let currentAd = 0
        let nextAd = 0

        while (true) {
            currentAd = nextAd
            nextAd = (currentAd+1) % adCount;
            (adElems[currentAd] as HTMLElement).style.display = "none";
            (adElems[nextAd] as HTMLElement).style.display = "block"
            yield currentAd
        }
    }
}
