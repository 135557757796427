import AdRotator from './ad-rotator/AdRotator';

const rotator = new AdRotator()
let adContainers = document.querySelectorAll(".bsaPopupWrapper, .bsaProContainerNew")

for (let adContainer of adContainers) {
    rotator.add({
        adContainer,
        interval: 5000
    })
}

rotator.init()